<template>
  <div id="AccountingScreen">
    <v-container>
      <v-row class="justify-center">
        <v-col class="col-7">
          <v-card :loading="loadingStatus">
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>LABA RUGI BULAN {{ currentMonth.toUpperCase() }}</h5>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon @click="refreshData">mdi-refresh</v-icon>
            </v-app-bar>
            <v-card-text>
              <div class="text--primary text-center text-h3 py-5">
                {{ formatRupiah(accountingData.laba_bersih) }}
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="showDialog">
                Lihat Detail
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Laba Rugi Bulan {{ currentMonth }}</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col class="col-12 col-md-4">
                <v-card>
                  <v-card-text>
                    <div>Total Pendapatan dari Penjualan</div>
                    <div class="text--primary text-h6">
                      {{ formatRupiah(accountingData.penjualan) }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="col-12 col-md-4">
                <v-card>
                  <v-card-text>
                    <div>Total Harga Pokok Penjualan</div>
                    <div class="text--primary text-h6">
                      {{ formatRupiah(accountingData.harga_pokok_penjualan) }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="col-12 col-md-4">
                <v-card>
                  <v-card-text>
                    <div>Total Laba Kotor</div>
                    <div class="text--primary text-h6">
                      {{ formatRupiah(accountingData.laba_kotor) }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-card class="my-5">
              <v-app-bar dense color="transparent">
                <v-toolbar-title>
                  <h5>Daftar Keperluan</h5>
                </v-toolbar-title>
              </v-app-bar>

              <v-card-text class="pa-0">
                <template>
                  <v-data-table
                    :headers="header"
                    :items="accountingData.detail_operasional"
                    class="elevation-0"
                    mobile-breakpoint="0"
                  >
                    <template slot="item" slot-scope="props">
                      <tr>
                        <td>{{ props.item.keterangan }}</td>
                        <td>{{ formatRupiah(props.item.value) }}</td>
                        <td>{{ props.item.date }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text--primary text-end text-h6">
                  Total Biaya Operasional :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text--primary text-end text-h6">
                  {{ formatRupiah(accountingData.total_biaya_operasional) }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-12 col-md-4">
                <v-card>
                  <v-card-text>
                    <div>Laba Bersih Sebelum Pajak</div>
                    <div class="text--primary text-h6">
                      {{
                        formatRupiah(accountingData.laba_bersih_sebelum_pajak)
                      }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="col-12 col-md-4">
                <v-card>
                  <v-card-text>
                    <div>Pajak Penghasilan (10%)</div>
                    <div class="text--primary text-h6">
                      {{ formatRupiah(accountingData.beban_pajak_penghasian) }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="col-12 col-md-4">
                <v-card color="success">
                  <v-card-text>
                    <div class="white--text">Total Laba Bersih</div>
                    <div class="white--text text-h6">
                      {{ formatRupiah(accountingData.laba_bersih) }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      header: [
        { text: "Keperluan", sortable: false },
        { text: "Nominal", sortable: false },
        { text: "Tanggal", ortable: false }
      ]
    };
  },

  mounted() {
    this.getCurrentMonth();
    this.getAccounting();
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    accountingData() {
      return this.$store.getters["accounting/getAccounting"];
    }
  },

  methods: {
    showDialog() {
      this.dialog = true;
    },
    getAccounting() {
      const today = new Date();

      let date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + "01";

      let params = {
        transaction_date: date
      };
      this.$store.dispatch("accounting/getAll", params);
    },
    refreshData() {
      const today = new Date();

      let date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + "01";

      let params = {
        transaction_date: date
      };

      this.$store.dispatch("accounting/getAll", params);
    }
  }
};
</script>
